import { motion } from 'framer-motion'
import { useLocale } from 'next-intl'
import { tw } from 'utils/classnames'

import { Link } from '@ask-cf/components/link'
import { ROUTES } from '@ask-cf/constants/app-routes'
import { Topic } from '@ask-cf/constants/topics'

export function TopicBadge({ topic, className }: { topic: Topic; className?: string }) {
  const locale = useLocale()
  const isSupport = topic.slug === 'support-questions'
  const isOther = topic.slug === 'other'
  const Component = ({
    innerClassName,
    children,
  }: React.PropsWithChildren<{ innerClassName: string }>) => {
    return isSupport || isOther ? (
      <div className={className}>
        <div className={innerClassName}>{children}</div>
      </div>
    ) : (
      <motion.p
        whileTap={{ scale: isSupport ? 1 : 0.9 }}
        whileHover={{ scale: isSupport ? 1 : 1.03 }}
        className={className}
      >
        <Link href={ROUTES.TOPIC_DETAILS(topic.slug, locale)} className={innerClassName}>
          {children}
        </Link>
      </motion.p>
    )
  }
  return (
    <Component
      innerClassName={tw(
        'rounded-2 flex items-center gap-1.5 px-3 py-1.5 text-xs font-medium leading-[16.2px] xl:text-sm xl:leading-[18.9px]',
        topic.textColor,
        topic.bgColor,
      )}
    >
      <topic.iconComponent className={topic.iconColor} width={16} height={16} />
      <span>{topic.title}</span>
    </Component>
  )
}
